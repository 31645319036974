import React from 'react';
import { FaShieldAlt, FaLaptop, FaGlobeAmericas, FaSmile } from 'react-icons/fa';

const Services = () => {
  const services = [
    {
      icon: <FaShieldAlt className="h-8 w-8 text-orange-500" />,
      title: 'Kalite Güvencesi',
      description: 'Kaliteyi ön planda tutarak, en iyi hizmeti sunuyoruz.'
    },
    {
      icon: <FaLaptop className="h-8 w-8 text-orange-500" />,
      title: 'Gelişmiş Teknoloji',
      description: 'Gelişmiş teknolojiler kullanarak, en iyi sonuçları elde ediyoruz.'
    },
    {
      icon: <FaGlobeAmericas className="h-8 w-8 text-orange-500" />,
      title: 'Evrensel Tasarım',
      description: 'Evrensel tasarım anlayışı ile herkes için mükemmel görünüm.'
    },
    {
      icon: <FaSmile className="h-8 w-8 text-orange-500" />,
      title: '%100 Müşteri Memnuniyeti',
      description: 'Müşteri memnuniyeti odaklı çalışarak, %100 müşteri memnuniyeti.'
    }
  ];

  return (
    <div className="bg-gradient-to-br from-white  py-16 sm:py-24">
      <div className="w-full">
         <img
           className="w-full h-[500px] object-cover object-center brightness-50"
           src="/images/s.jpg"
           alt="Tiger Thermoforming Factory"
         />
       </div>
      <div className="max-w-7xl mt-12 mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900 mb-4">
            Hizmetlerimiz
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Kalite, teknoloji ve müşteri memnuniyeti odaklı çözümlerimizle yanınızdayız.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {services.map((service, index) => (
            <div 
              key={index}
              className="bg-white shadow-lg rounded-xl overflow-hidden transition-all duration-300 ease-in-out hover:shadow-2xl hover:-translate-y-2 border border-orange-100"
            >
              <div className="p-6 text-center">
                <div className="flex justify-center items-center mb-4">
                  <div className="bg-orange-100 p-4 rounded-full">
                    {service.icon}
                  </div>
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-4">
                  {service.title}
                </h3>
                <p className="text-gray-600">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;