import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

import Footer from './Components/Footer';
import Navigator from './Components/Navigator';

import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ServicesPage from './pages/Services';
import ContactPage from './pages/Contact';

const App = () => {
  return (
    <Router>
      <div className="min-h-screen">
        <Navigator />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactPage />} />
          {/* You can add more specific routes here if needed */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;