import React from 'react';

const AboutSection = () => {
  return (
    <div className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-2">Hakkımızda</h2>
        <div className="w-16 h-1 bg-orange-500 mx-auto mb-12"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className='flex justify-center items-center'>
            <img
              src="/images/g.jpg"
              alt="About Us"
              className="rounded-lg shadow-lg w-[60%] items-center"
            />
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Biz Kimiz</h3>
            <p className="text-gray-600 mb-6">

            Tiger Thermoforming, 2023 yılında İstanbul, Türkiye’de kurulmuş olup, çok istasyonlu termoform makineleri için yüksek teknolojiye sahip kalıpların tasarımı ve üretimi konusunda 10 yıllık deneyime sahip bir uzmanlık sunmaktadır. Yenilikçi çözümleri ve üstün kalite standartlarıyla sektörde fark yaratan şirketimiz, termoform makinelerinin dünya çapında tanınan birçok markasıyla ilgili geniş bir bilgi birikimine ve derin bir deneyime sahiptir.

Müşteri memnuniyetini her zaman öncelik olarak belirleyen Tiger Thermoforming, farklı sektörlerden gelen çeşitli ihtiyaçlara uygun çözümler sunma kapasitesine sahiptir. Yüksek teknoloji, detaylı mühendislik ve hassas üretim süreçlerimiz sayesinde müşterilerimize en verimli ve güvenilir çözümleri sunarak, global ölçekte rekabet gücü kazanmalarını destekliyoruz.

10 yıllık sektörel tecrübemizle, sürekli gelişen dinamiklere ayak uydurmayı ve inovasyonu işimizin merkezine koymayı misyon edinen Tiger Thermoforming, hem ulusal hem de uluslararası pazarda güçlü bir iş ortağı olmayı hedeflemektedir. Uzman kadromuz ve ileri teknolojimizle, termoform kalıp üretiminde standartları yeniden tanımlamaya devam ediyoruz.
            </p>
           
            {/* <button className="bg-orange-500 hover:bg-orange-600 text-white font-semibold py-3 px-8 rounded-full transition duration-300">
              DAHA FAZLA
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;