import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import Map from '../Components/Map';

const Contact = () => {
   return (
     <div className="bg-white relative pt-[80px]"> {/* Navbar height padding */}
       <div className="w-full">
         <img
           className="w-full h-[500px] object-cover object-center brightness-50"
           src="/images/s.jpg"
           alt="Tiger Thermoforming Factory"
         />
       </div>
       <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
         <div className="lg:grid lg:grid-cols-2 lg:gap-8">
           <div className="bg-gray-50 px-6 py-12 sm:px-16 sm:py-16 lg:py-24 z-10 relative"> {/* Added z-10 and relative positioning */}
             <div className="max-w-lg mx-auto lg:max-w-none">
               <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                 İletişim
               </h2>
               <p className="mt-4 text-lg text-gray-600">
                 Üretim ihtiyaçlarınızda size yardımcı olmak için buradayız. Kişiselleştirilmiş çözümler için bize ulaşın.
               </p>
               <dl className="mt-8 text-base text-gray-700 space-y-6">
                 <div className="flex items-start">
                   <FaMapMarkerAlt className="flex-shrink-0 h-6 w-6 text-orange-600 mr-4 mt-1" />
                   <div>
                     <dt className="font-semibold">Adres</dt>
                     <dd>
                       Orta mah. İbrahim ağa cad. No:20
                       Emintsş 2, Sanayi Sitesi No: Z14-15
                       Bayrampaşa / İstanbul, Turkey
                     </dd>
                   </div>
                 </div>
                 <div className="flex items-start">
                   <FaPhoneAlt className="flex-shrink-0 h-6 w-6 text-orange-600 mr-4 mt-1" />
                   <div>
                     <dt className="font-semibold">İletişim Numarası</dt>
                     <dd>
                       <div>Mobil: +90 (539) 324 96 22</div>
                       <div>Ofis: +90 (212) 555 44 33</div>
                     </dd>
                   </div>
                 </div>
                 <div className="flex items-start">
                   <FaEnvelope className="flex-shrink-0 h-6 w-6 text-orange-600 mr-4 mt-1" />
                   <div>
                     <dt className="font-semibold">Email Adresi</dt>
                     <dd>
                       <div>gursel@tiger.web.tr</div>
                     </dd>
                   </div>
                 </div>
               </dl>
             </div>
           </div>
           <div className="relative z-0"> 
             <Map />
           </div>
         </div>
       </div>
     </div>
   );
};

export default Contact;