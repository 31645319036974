import React from 'react';

const CTASection = () => {
  return (
    <div className="bg-orange-500 py-8">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-white mb-4">
         İhtiyacınız olan termoform kalıbı için bize ulaşın!
        </h2>
        <div className="w-16 h-1 bg-white mx-auto mb-8"></div>
        <p className="text-white text-lg max-w-3xl mx-auto mb-8">
        Tiger Thermoforming: Yüksek teknoloji ve yenilikçi çözümlerle termoform kalıplarında geleceği şekillendiriyoruz, sektöre kalite ve güvenin yeni standartlarını getiriyoruz!
        </p>
        {/* <button className="bg-white text-gray-900 hover:bg-gray-100 font-semibold py-3 px-8 rounded-full transition duration-300">
          GET STARTED!
        </button> */}
      </div>
    </div>
  );
};

export default CTASection;