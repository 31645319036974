import React from 'react';
import { Diamond, Laptop, Globe, Heart } from 'lucide-react';

const ServicesSection = () => {
  const services = [
    {
      icon: Diamond,
      title: 'Kalite Güvencesi',
      description: 'Kaliteyi ön planda tutarak, en iyi hizmeti sunuyoruz.'
    },
    {
      icon: Laptop,
      title: 'Gelişmiş Teknoloji',
      description: 'Gelişmiş teknolojiler kullanarak, en iyi sonuçları elde ediyoruz.'
    },
    {
      icon: Globe,
      title: 'Evrensel Tasarım',
      description: 'Evrensel tasarım anlayışı ile herkes için mükemmel görünüm.'
    },
    {
      icon: Heart,
      title: '%100 Müşteri Memnuniyeti',
      description: 'Müşteri memnuniyeti odaklı çalışarak, %100 müşteri memnuniyeti.'
    }
  ];

  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-2">Hizmetlerimiz</h2>
        <div className="w-16 h-1 bg-orange-500 mx-auto mb-12"></div>
        
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {services.map(({ icon: Icon, title, description }, index) => (
            <div key={index} className="text-center">
              <Icon className="w-12 h-12 text-orange-500 mx-auto mb-4" />
              <h3 className="text-xl font-bold mb-2">{title}</h3>
              <p className="text-gray-600">
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;