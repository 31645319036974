import React from 'react';
import { MapPin, Phone, Mail } from 'lucide-react';
import Map from './Map';

const ContactSection = () => {
  return (
    <div className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-2">İletişim</h2>
        <div className="w-16 h-1 bg-orange-500 mx-auto mb-12"></div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <h3 className="text-2xl font-bold mb-6">Bize Ulaşın</h3>

            <div className="space-y-4">
              <div className="flex items-center">
                <MapPin className="w-6 h-6 text-orange-500 mr-4" />
                <span className="w-1/2 text-gray-600">
                  Orta mah. ibrahim ağa cad. No:20 Emintaş 2
                  Sanayi Sitesi No: Z14-15 Bayrampaşa / İstanbul
                </span>
              </div>
              <div className="flex items-center">
                <a
                  href="tel:+905393249622"
                  className="flex items-center text-gray-600 hover:text-orange-500 transition-colors"
                >
                  <Phone className="w-6 h-6 text-orange-500 mr-4" />
                  <span>+90 (539) 324 96 22</span>
                </a>
              </div>
              <div className="flex items-center">
                <a
                  href="mailto:gursel@tiger.web.tr"
                  className="flex items-center text-gray-600 hover:text-orange-500 transition-colors"
                >
                  <Mail className="w-6 h-6 text-orange-500 mr-4" />
                  <span>gursel@tiger.web.tr</span>
                </a>
              </div>
            </div>

            {/* Sosyal Medya Linkleri - href'ler eklendi
            <div className="mt-8">
              <h4 className="text-xl font-bold mb-4">Sosyal Medya</h4>
              <div className="flex space-x-4">
                <a 
                  href="https://facebook.com/your-page" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-orange-500 hover:text-orange-600"
                  aria-label="Facebook sayfamız"
                >
                  <Facebook className="w-6 h-6" />
                </a>
                <a 
                  href="https://twitter.com/your-page" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-orange-500 hover:text-orange-600"
                  aria-label="Twitter sayfamız"
                >
                  <Twitter className="w-6 h-6" />
                </a>
                <a 
                  href="https://instagram.com/your-page" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-orange-500 hover:text-orange-600"
                  aria-label="Instagram sayfamız"
                >
                  <Instagram className="w-6 h-6" />
                </a>
              </div>
            </div> */}
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-6">Konum</h3>
            <div className="w-full h-64 bg-gray-200 rounded-lg">
              <Map />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;