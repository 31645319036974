import React from 'react';

const About = () => {
  const features = [
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549A4.49 4.49 0 0 1 21.75 7.5c0 1.357-.6 2.573-1.549 3.397A4.49 4.49 0 0 1 18 14.25c-1.357 0-2.573-.6-3.397-1.549A4.49 4.49 0 0 1 9 9.75c0-1.357.6-2.573 1.549-3.397A4.49 4.49 0 0 1 12 4.5a4.49 4.49 0 0 1 3.397 1.549ZM12 15.75a4.5 4.5 0 0 0-3.397 1.549A4.491 4.491 0 0 0 7.5 16.5c-1.357 0-2.573.6-3.397 1.549A4.49 4.49 0 0 0 2.25 21h9.75a4.49 4.49 0 0 0-1.549-3.397A4.491 4.491 0 0 0 7.5 16.5a4.49 4.49 0 0 0-3.397 1.549A4.491 4.491 0 0 0 2.25 21h9.75a4.49 4.49 0 0 0-1.549-3.397A4.49 4.49 0 0 0 12 15.75ZM16.5 18a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0 2.25a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Zm0 2.25a6.75 6.75 0 1 0 0-13.5 6.75 6.75 0 0 0 0 13.5Z" clipRule="evenodd" />
        </svg>
      ),
      title: "Uzmanlık ve Deneyim",
      description: "Dünya çapında tanınan termoform makine markalarıyla ilgili geniş bilgi birikimi ve tecrübesiyle, müşterilerine ihtiyaca özel, işlevsel ve dayanıklı kalıp çözümleri sunmaktayız.",
      bgColor: "bg-green-100",
      textColor: "text-green-800"
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M14.615 1.595a.75.75 0 0 1 .637.721v11.495a.75.75 0 0 1-.495.714 1.5 1.5 0 0 0-.364 2.434l.36.36a.75.75 0 0 1-1.06 1.06l-1.848-1.848a.75.75 0 0 1 0-1.06l1.847-1.847a.75.75 0 1 1 1.06 1.06l-.359.36a.75.75 0 0 1 .495-.715c.646-.175 1.12-.719 1.12-1.395V2.316a.75.75 0 0 1 .637-.721 3 3 0 1 1 0 5.964.75.75 0 0 1-.637-.72v-1.19a.75.75 0 0 1 .495-.714 1.5 1.5 0 0 0 0-2.864.75.75 0 0 1-.495-.714V4.456a.75.75 0 0 1 .637-.721 3 3 0 1 1 0 5.964Zm-9.75 2.121a.75.75 0 0 1 0-1.06l1.847-1.847a.75.75 0 0 1 1.06 0l1.848 1.847a.75.75 0 1 1-1.06 1.06l-.36-.359a.75.75 0 0 1-.495.714v11.495a.75.75 0 0 1 .495.714 1.5 1.5 0 0 0 .364 2.434l-.36.36a.75.75 0 0 1 1.06 1.06l-1.847 1.848a.75.75 0 0 1-1.06 0l-1.848-1.848a.75.75 0 1 1 1.06-1.06l.359.36a.75.75 0 0 1 .495-.716V2.97a.75.75 0 0 1-.495-.714 1.5 1.5 0 0 0-1.12-1.395.75.75 0 0 1-.495-.714 3 3 0 1 1 0 5.964Zm3-1.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" clipRule="evenodd" />
        </svg>
      ),
      title: "Yüksek Teknoloji ve İnovasyon",
      description: "İleri teknolojiler ve hassas mühendislik süreçleriyle en karmaşık kalıpları başarıyla üretiyoruz. Sürekli inovasyonla sektör dinamiklerine hızla uyum sağlıyoruz.",
      bgColor: "bg-blue-100",
      textColor: "text-blue-800"
    },
    {
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM9.763 8.111a1.5 1.5 0 1 0-2.646 1.39l1.703 3.114a1.5 1.5 0 0 0 1.032.729l1.555.295-.899 1.791a1.5 1.5 0 1 0 2.645 1.328l1.608-3.011a1.5 1.5 0 0 0-.731-2.04l-1.557-.294.898-1.79a1.5 1.5 0 1 0-2.646-1.328L9.763 8.111Z" clipRule="evenodd" />
        </svg>
      ),
      title: "Global Güç ve Vizyon",
      description: "Ulusal ve uluslararası pazarlarda lider olmayı hedefleyen şirketimiz, sürdürülebilirlik ve inovasyonu merkeze alarak, termoform sektöründe güvenilir bir marka konumundayız.",
      bgColor: "bg-purple-100",
      textColor: "text-purple-800"
    }
  ];

  return (
    <div className="bg-white">
      <div className="w-full mb-12">
        <img
          className="w-full h-[500px] object-cover object-center brightness-50"
          src="/images/s.jpg"
          alt="Tiger Thermoforming Factory"
        />
      </div>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-16">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold text-gray-900">Tiger Thermoforming: Termoform Sektöründe Yenilikçi Çözümler</h2>
          <p className="mt-6 text-xl text-gray-500 max-w-4xl mx-auto text-center">
            2023 yılında İstanbul'da kurulan Tiger Thermoforming, çok istasyonlu termoform makineleri için yüksek teknolojiye sahip kalıpların tasarımı ve üretiminde uzmanlaşmıştır. 10 yılı aşkın sektörel deneyimi, yenilikçi yaklaşımı ve üstün kalite standartlarıyla şirketimiz, dünya çapında tanınan termoform makine markalarıyla ilgili derin bilgi birikimi ve tecrübeye sahiptir.
          </p>
        </div>
        <div className="md:w-full text-center mb-12">
          <p className="text-gray-500 max-w-4xl mx-auto">
            Yüksek teknoloji ve hassas mühendislik süreçleriyle en karmaşık kalıp ihtiyaçlarını karşılıyor, farklı sektörlere özel çözümler sunuyoruz. Müşteri memnuniyetini öncelik kabul eden Tiger Thermoforming, üretimde detaylara verdiği önem ve kaliteye olan bağlılığıyla müşterilerinin global pazardaki rekabet gücünü artırmayı hedefler.
            Ulusal ve uluslararası pazarlarda güçlü bir iş ortağı olma vizyonuyla hareket eden şirketimiz, sürdürülebilirlik ve inovasyonu işinin merkezine koymaktadır. Uzman ekibimiz ve ileri teknolojimizle sektörde standartları yeniden tanımlamaya devam ediyor, termoform kalıp üretiminde güvenilir ve yenilikçi bir marka olmayı sürdürüyoruz.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
          {features.map((item, index) => (
            <div 
              key={index} 
              className="bg-white shadow-lg rounded-xl overflow-hidden transform transition duration-300 hover:scale-105"
            >
              <div className={`p-6 ${item.bgColor}`}>
                <div className="flex items-center mb-4">
                  <div className={`p-3 rounded-full ${item.bgColor} ${item.textColor} mr-4`}>
                    {item.icon}
                  </div>
                  <h4 className="text-lg font-bold text-gray-900">{item.title}</h4>
                </div>
                <p className="text-base text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;