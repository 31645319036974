import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Navbar arka plan ve metin renk sınıfları
  const navbarBgClass = isHomePage 
    ? (isScrolled ? 'bg-white' : 'bg-transparent') 
    : 'bg-white shadow-md';
  
  const textColorClass = isHomePage 
    ? (isScrolled ? 'text-gray-700' : 'text-white') 
    : 'text-gray-700';

  const hoverColorClass = 'hover:text-[#cb6d38] transition-colors';

  return (
    <div
      className={`w-full fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${navbarBgClass}`}
    >
      <div className="flex justify-between items-center px-4 md:px-8 lg:px-16 py-5">
        
        <div className="flex justify-start md:ml-32">
          <Link to="/">
            <img 
              src="/images/LOGO.png" 
              alt="Logo" 
              className={`w-1/4 ${isHomePage && !isScrolled ? 'filter brightness-0 invert' : ''}`}
            />
          </Link>
        </div>
        
        {/* Desktop Menu */}
        <div className="hidden md:flex gap-8 mr-32">
          <Link
            to="/"
            className={`text-lg ${textColorClass} ${hoverColorClass}`}
          >
            Anasayfa
          </Link>
          <Link
            to="/about"
            className={`text-lg ${textColorClass} ${hoverColorClass}`}
          >
            Hakkımızda
          </Link>
          <Link
            to="/services"
            className={`text-lg ${textColorClass} ${hoverColorClass}`}
          >
            Hizmetlerimiz
          </Link>
          <Link
            to="/contact"
            className={`text-lg ${textColorClass} ${hoverColorClass}`}
          >
            İletişim
          </Link>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className={`${textColorClass} ${hoverColorClass}`}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute w-full bg-white z-50 py-4 px-4 mt-4 border-t border-gray-800">
          <div className="flex flex-col gap-4">
            <Link
              to="/"
              className="w-full text-left px-4 py-2 text-lg font-bold text-gray-700 hover:text-[#cb6d38]"
              onClick={() => setIsMenuOpen(false)}
            >
              Anasayfa
            </Link>
            <Link
              to="/hakkimizda"
              className="w-full text-left px-4 py-2 text-lg font-bold text-gray-700 hover:text-[#cb6d38]"
              onClick={() => setIsMenuOpen(false)}
            >
              Hakkımızda
            </Link>
            <Link
              to="/services"
              className="w-full text-left px-4 py-2 text-lg font-bold text-gray-700 hover:text-[#cb6d38]"
              onClick={() => setIsMenuOpen(false)}
            >
              Hizmetlerimiz
            </Link>
            <Link
              to="/contact"
              className="w-full text-left px-4 py-2 text-lg font-bold text-gray-700 hover:text-[#cb6d38]"
              onClick={() => setIsMenuOpen(false)}
            >
              İletişim
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;