import React from 'react';

import HeroSection from '../Components/heroSection';
import ServicesSection from '../Components/ServicesSection';
import CTASection from '../Components/CTASection';
import AboutSection from '../Components/AboutSection';
import ContactSection from '../Components/ContactSection';

const Home = () => {
  return (
    <div className="min-h-screen">
     
      <div>
      <HeroSection />

      </div>
      <div id="ServicesSection">
      <ServicesSection />

      </div>
      <div>
      <CTASection />

      </div>
      <div id="AboutSection">
      <AboutSection />

      </div>
      <div id='ContactSection'>
      <ContactSection />

      </div>
  

    </div>
  );
};

export default Home;