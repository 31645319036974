import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <img src="images/LOGO.png" alt="Tiger Thermoforming Logo" className="w-[60%] item-center" />
          </div>
          
          <div>
            <h4 className="text-xl font-bold mb-4">Hızlı Linkler</h4>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-400 hover:text-white">
                  Anasayfa
                </a>
              </li>
              <li>
                <a href="#AboutSection" className="text-gray-400 hover:text-white">
                  Hakkımızda
                </a>
              </li>
              <li>
                <a href="#ServicesSection" className="text-gray-400 hover:text-white">
                  Hizmetler
                </a>
              </li>
              <li>
                <a href="#ContactSection" className="text-gray-400 hover:text-white">
                  İletişim
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h4 className="text-xl font-bold mb-4">Hizmetlerimiz</h4>
            <ul className="space-y-2">
              <li>
                <a href="#services-quality" className="text-gray-400 hover:text-white">
                  Kalite Güvencesi
                </a>
              </li>
              <li>
                <a href="#services-technology" className="text-gray-400 hover:text-white">
                  Gelişmiş Teknoloji
                </a>
              </li>
              <li>
                <a href="#services-design" className="text-gray-400 hover:text-white">
                  Evrensel Tasarım
                </a>
              </li>
              <li>
                <a href="#services-satisfaction" className="text-gray-400 hover:text-white">
                  %100 Müşteri Memnuniyeti
                </a>
              </li>
            </ul>
          </div>
          
        </div>

        <div className="border-t border-gray-800 mt-6 pt-6 justify-center items-center text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Tiger Thermoforming. Tüm hakları saklıdır.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;