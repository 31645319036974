import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative min-h-screen">
      {/* Background Image */}
      <img 
        src="/images/h.jpg"
        alt="Hero Background"
        className="absolute inset-0 h-full w-full object-cover z-0"
      />
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      
      {/* Content */}
      <div className="relative z-20 flex flex-col items-center justify-center min-h-screen text-center px-4 sm:px-8">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 leading-tight">
        Termoform Teknolojisinde Güvenilir<br />Çözüm Ortağınız
        </h1>
        <p className="text-white text-base sm:text-lg md:text-xl max-w-3xl mb-8 leading-relaxed">
        Yüksek teknolojiye sahip kalıplarımız ve uzman ekibimizle, çok istasyonlu termoform makineleri için en verimli çözümleri sunuyor, global standartlarda kaliteyi ayağınıza getiriyoruz.
        </p>
        <a 
          href="https://wa.me/905393249622" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-green-500 hover:bg-green-600 text-white font-semibold py-3 px-8 rounded-xl transition duration-300"
        >
          Whatsapp 
        </a>
      </div>
    </div>
  );
};

export default HeroSection;
